import { Box, Button, Modal, Typography } from "@mui/material";
import AuthContext from "contexts/AuthContext";
import React, { useState, useEffect, useRef, useContext } from "react";

const SsoLoginModal = ({ programId }) => {
  const [open, setOpen] = useState(false);
  const iframeRef = useRef(null);
  const { setAuth } = useContext(AuthContext);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== process.env.REACT_APP_BASE_PATH) return; // Update with your backend app URL

      if (event.data && event.data.token) {
        const tokenData = JSON.parse(event.data.token);
        const identityData = JSON.parse(event.data.identity);
        const userData = JSON.parse(event.data.user);

        setAuth(userData, `Bearer ${tokenData?.value}`);
        setOpen(false);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "52%",
    height: "85%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: '1px',
    overflow: 'hidden'
  };

  return (
    <div style={{ marginTop: "15px" }}>
      <Button
        size="large"
        variant="contained"
        sx={{
          p: 2,
          width: 250,
          borderRadius: 50,
          bgcolor: "secondary.main",
          color: "#fff",
        }}
        onClick={() => setOpen(true)}
      >
        Login with SSO
      </Button>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <iframe
            ref={iframeRef}
            title="SSO Login Frame"
            src={`${process.env.REACT_APP_SSO_LOGIN_PATH}?program=${programId}`}
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </Box>
      </Modal>
    </div>
  );
};

export default SsoLoginModal;
